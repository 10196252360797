'use client';

/**
 * Use a custom wrapper for redux Provider to mark it as 'use client'.
 */

import { Provider } from 'react-redux';
import { store } from '.';

export type IProps = React.PropsWithChildren

const StoreProvider = ({ children }: IProps) => (
    <Provider store={store}>
        {children}
    </Provider>
);

export default StoreProvider;