'use client';

import { useEffect } from 'react';
import {
    usePathname,
    useSearchParams,
} from 'next/navigation';
import { IProps } from './YandexMetrika';

/**
 * Компонент для отслеживания переходов между страницами.
 */
export default function YandexMetrikaClient({ id }: IProps) {
    const pathName = usePathname();
    const searchParams = useSearchParams();

    useEffect(() => {
        (window as any).ym?.(id, 'hit', window.location.href);
    }, [ id, pathName, searchParams ]);

    return null;
}