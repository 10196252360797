import { combineReducers } from 'redux';
// import { all } from 'redux-saga/effects';
import { objectHandler } from '@frontend/jetlend-core/src/ducks/object';
import {
    IPartnersRevenuePointApiModel,
    IPartnersRevenueStatisticsApiModel,
} from '@app/models/partners/partners';

export const PREFIX = 'partners';

// export const partnersRevenueChartHandler = simpleChartApiHandler<
// 	IPartnersRevenuePointApiModel,
// 	any
// >(PREFIX, 'revenue_chart', apiGetPartnersRevenueChart);

// export const partnersRevenueStatisticsHandler = simpleApiHandler<IPartnersRevenueStatisticsApiModel>(
//     PREFIX, 'jetlend_statistics', apiGetPartnersRevenueStatistics
// );

export const partnersRevenueChartHandler = objectHandler<IPartnersRevenuePointApiModel[]>(
    PREFIX, 'revenue_chart',  () => ([18, 26, 33, 40, 50].reduce((acc, value) => {
        acc.push({ revenue_percentage: value });
        return acc;
    }, []))
);

export const partnersRevenueStatisticsHandler = objectHandler<IPartnersRevenueStatisticsApiModel>(
    PREFIX, 'revenue_statistics', () => (
        { max_percentage: 50 }
    )
);


export const reducer = combineReducers({
    ...partnersRevenueChartHandler.reducerInfo,
    ...partnersRevenueStatisticsHandler.reducerInfo,
});

// export function* rootSaga() {
//     yield all([
//         ...partnersRevenueChartHandler.effects,
//         ...partnersRevenueStatisticsHandler.effects,
//     ]);
// }