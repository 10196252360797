import { combineReducers } from 'redux';
import { simpleFormHandler } from '@frontend/jetlend-core/src/ducks/form';
import { all } from 'redux-saga/effects';
import { call } from '@redux-saga/core/effects';
import { apiPostTestSign } from '@app/services/client/testsService';
import { ITestMetadata } from '@app/models/tests';

export const PREFIX = 'tests';

export const testSignMetadata = simpleFormHandler<ITestMetadata>(PREFIX, 'signed-test-url', undefined, {
    *apiMethod (values: {id: string}) {
        return yield call(apiPostTestSign, values.id);
    },
}, {}
);

export const reducer = combineReducers({
    ...testSignMetadata.reducerInfo,
});

export function* rootSaga() {
    yield all([
        ...testSignMetadata.effects,
    ]);
}
